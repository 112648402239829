import { IconEmail, Text } from '@butcherbox/freezer'
import { rem } from 'design'
import {
  LinkButton,
  LinkExternal,
  LinkInternal,
} from 'design/components/Typography/Links'
import React from 'react'
import { ContactList, ContactListItem, CtaContainer } from './FAQs.styles'
import { openChatWidget } from '~/utils/zendesk'
import { TRY_BB_URL } from '~/routes/constants'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import useGetOptimizelyConfig from '~/hooks/useGetOptimizelyConfig'
import { FaqMetrics } from '~/experiments/cs-409-faqs-page/constants'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import { JOIN_586_VARIANTS } from '~/types/join-586-bypass-get-started'
import useSetCartOffer from '~/hooks/useSetCartOffer'
import IconChatBubble from 'design/components/Icons/defs/ChatBubble'
import IconPhone from 'design/components/Icons/defs/Phone'

export default function ContactCTA() {
  const { track } = useGetOptimizelyConfig()

  const { cartDealUrl } = useSetCartOffer()
  const [shouldBypassGetStarted] = useOptimizelyExperiment(
    'join-586__bypassing-get-started'
  ) as [JOIN_586_VARIANTS, null, null]

  return (
    <CtaContainer>
      <Text marginBottom={24} mx={24} textAlign={'center'} variant="Title">
        Have additional questions? Get in touch.
      </Text>
      {/* <ResponsiveText
        as={'h2'}
        desktop={H1}
        mb={rem(24)}
        mobile={H2Bold}
        px={rem(24)}
        textAlign="center"
      >
        
      </ResponsiveText> */}
      <ContactList>
        <ContactListItem>
          <IconEmail
            customColor={{ base: 'spicedCrimson' }}
            marginRight={8}
            pixelSize={20}
            size="fixed"
          />
          <LinkExternal href="mailto:support@butcherbox.com" target="_self">
            support@butcherbox.com
          </LinkExternal>
        </ContactListItem>
        <ContactListItem>
          <IconChatBubble
            color="bb.spicedCrimson"
            height={rem(16.3)}
            mr={rem(8)}
            width={rem(16)}
          />
          <LinkButton onClick={() => openChatWidget()}>Live Chat</LinkButton>
        </ContactListItem>
        <ContactListItem>
          <IconPhone
            color="bb.spicedCrimson"
            height={rem(24)}
            mr={rem(8)}
            width={rem(14.4)}
          />
          <LinkExternal href="tel:+1-855-981-8568" target="_self">
            (855) 981-8568
          </LinkExternal>
        </ContactListItem>
      </ContactList>
      <LargeButtonPrimary
        as={LinkInternal}
        href={
          shouldBypassGetStarted &&
          shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED
            ? cartDealUrl
            : TRY_BB_URL
        }
        minW={{ base: rem(260), tablet: rem(320) }}
        onClick={() => track(FaqMetrics.CHOOSE_YOUR_PLAN_CTA)}
      >
        Choose Your Plan
      </LargeButtonPrimary>
    </CtaContainer>
  )
}
