import { rem, styled } from 'design'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'

const HERO_HEIGHT = 248

export const HeroImage = styled(ImageSetBackground)`
  height: ${rem(HERO_HEIGHT)};
  width: 100%;
  img {
    filter: brightness(50%);
  }
`

export const HeroContainer = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: ${rem(HERO_HEIGHT)};
  justify-content: center;
  text-align: center;
`

export const UnderlinedText = styled.span`
  text-decoration: underline;
`

export const List = styled.ul`
  list-style-position: outside;
  padding: 1rem 0;
`

export const ListItem = styled.li`
  margin-left: ${rem(16)};
  color: ${(p) => p.theme.colors.bb.spicedCrimson};
`

export const ListItemContent = styled.span`
  color: ${(p) => p.theme.colors.bb.slate};
`

export const FaqContentContainer = styled.section`
  max-width: ${rem(748)};
  margin: 0 ${rem(24)};
  display: flex;
  flex-direction: column;

  ${(p) => p.theme.media.tablet} {
    margin: 0 auto;
  }

  .faq-section__column {
    width: 100%;
  }

  .faq {
    &__button {
      padding: ${rem(25)} ${rem(16)} ${rem(21)};

      &--expanded {
        margin-bottom: ${rem(16)};
        padding-bottom: 0;
      }
    }

    &__body {
      max-width: 100%;
      padding: 0 ${rem(16)};
    }
  }
`

export const CtaContainer = styled.section`
  margin: 0 auto ${rem(64)};
  text-align: center;

  ${(p) => p.theme.media.tablet} {
    margin-bottom: ${rem(120)};
    min-width: ${rem(768)};
  }
`

export const ContactList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0 ${rem(24)};
  padding: ${rem(24)} 0 ${rem(32)};
  justify-content: center;
  border-top: 1px solid ${(p) => p.theme.colors.bb.silt};
  flex-direction: column;
  text-align: center;
  gap: ${rem(16)};

  ${(p) => p.theme.media.tablet} {
    flex-direction: row;
    gap: 0;
    margin: 0;
    padding: ${rem(30)} 0 ${rem(40)};
  }
`

export const ContactListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) => p.theme.media.tablet} {
    margin: 0 ${rem(16)};
    padding-right: ${rem(16)};
    border-right: 2px solid ${(p) => p.theme.colors.bb.silt};
    justify-content: flex-start;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-left: 0;
    }
  }
`
