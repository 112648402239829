import { REPORT_AN_ISSUE_URL } from '~/routes/AccountHelp/constants'
// zE comes from gatsby-plugin-zendesk-chat: https://github.com/garethpbk/gatsby-plugin-zendesk-chat
// it is further customized in BaseLayout via injected script
// It allows you to interact with the zendesk widget
declare global {
  interface ZendeskModern {
    (cb: Function): void
    (target: string, command: string, data?: Object): void
  }

  interface Window {
    zE?: ZendeskModern
    zESettings: Object
  }
}

export const openChatWidget = () => {
  window.zE && window.zE('webWidget', 'open')
}

export const toggleChatWidget = () => {
  window.zE && window.zE('webWidget', 'toggle')
}

if (typeof window !== 'undefined' && window.zE) {
  window.zE(function () {
    window.zE && window.zE('webWidget', 'chat:addTags', ['ButcherBox'])

    const mapping = {
      '/account/credits': ['rfrl'],
      '/account/your-box': ['acnt'],
      '/credits': ['rfrl'],
      '/how-it-works': ['hiw'],
      '/member': ['mbr'],
      '/sourcing': ['src'],
    }

    function setSuggestions() {
      const pathname = window.location.pathname
      const labels = []

      for (const page in mapping) {
        if (page.startsWith(pathname)) labels.push.apply(labels, mapping[page])
      }

      if (labels.length > 0) {
        // suggest Help Center articles based on page location
        window.zE &&
          window.zE('webWidget', 'helpCenter:setSuggestions', {
            labels: labels,
          })
      }
    }

    // this is triggered manually in gatsby-on-route-update.ts
    window.addEventListener('popstate', setSuggestions)
  })
}

export const generateReportAnIssueUrl = (
  userId: number,
  ftpId: number | null
) => {
  return `${REPORT_AN_ISSUE_URL}?ExternalDataReference=${userId}&ftpid=${ftpId}`
}
