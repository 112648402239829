import { rem } from '@butcherbox/freezer'
import { FlexProps } from '@chakra-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import ModuleFAQSection from '~/components/ModuleFAQSection/ModuleFAQSection'
import SEO from '~/layouts/seo'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import { cleanJsonStrings } from '~/utils/unicode'
import ContactCTA from './FAQs/ContactCTA'
import FAQHero from './FAQs/FAQHero'
import faqData from './FAQs/FAQs.data'
import { FaqContentContainer } from './FAQs/FAQs.styles'

export default function FAQs() {
  const data = cleanJsonStrings(
    useStaticQuery(graphql`
      query FAQsStaticQuery {
        pageData: contentfulProtectedPage(pageId: { eq: "faqs" }) {
          ...ProtectedPage
        }
      }
    `)
  )

  const { seo } = useProtectedPage(data?.pageData)

  const defaultContainerProps: FlexProps = {
    py: { base: rem(32), tablet: rem(40) },
    width: '100%',
  }

  return (
    <UnauthenticatedLayout
      SEO={
        <SEO
          description={seo?.description}
          image={seo?.image?.fixed?.src}
          title={seo?.title}
        />
      }
      title="FAQs"
    >
      <FAQHero />
      <FaqContentContainer>
        <ModuleFAQSection
          containerProps={{
            ...defaultContainerProps,
            pt: { base: rem(40), tablet: rem(90) },
          }}
          id={faqData.plansAndPricing.id}
          questions={faqData.plansAndPricing.questions}
          title={faqData.plansAndPricing.title}
        />
        <ModuleFAQSection
          containerProps={defaultContainerProps}
          id={faqData.animalWelfareAndProductInfo.id}
          questions={faqData.animalWelfareAndProductInfo.questions}
          title={faqData.animalWelfareAndProductInfo.title}
        />
        <ModuleFAQSection
          containerProps={{
            ...defaultContainerProps,
            pb: { base: rem(64), tablet: rem(80) },
          }}
          id={faqData.shippingAndDelivery.id}
          questions={faqData.shippingAndDelivery.questions}
          title={faqData.shippingAndDelivery.title}
        />
      </FaqContentContainer>
      <ContactCTA />
    </UnauthenticatedLayout>
  )
}
