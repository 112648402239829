import { Text } from '@butcherbox/freezer'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { HeroContainer, HeroImage } from './FAQs.styles'

export default function FAQHero() {
  const imageData = useStaticQuery(graphql`
    query {
      bg: contentfulImageSet(uniqueId: { eq: "faq_page_header" }) {
        ...ImageSet
      }
    }
  `)

  return (
    <HeroImage imageSet={imageData.bg}>
      <HeroContainer>
        <Text color="white" component="h1" variant="DisplayOne">
          FAQ
        </Text>
      </HeroContainer>
    </HeroImage>
  )
}
