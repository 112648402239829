import {
  Lockup,
  Text,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@butcherbox/freezer'
import React from 'react'
import { IFrequentlyAskedQuestionCollapse } from './FrequentlyAskedQuestion.types'

function FrequentlyAskedQuestion({
  question,
  expanded = false,
  ...props
}: IFrequentlyAskedQuestionCollapse) {
  // When accordions are closed, set tabIndex to -1
  // so the links can be present for SEO value
  // but not tab focused by the keyboard,
  // and when open, set to undefined so the user can tab to them

  const [tabIndex, setTabIndex] = React.useState<undefined | -1>(-1)

  const onChangeHandler = React.useCallback((currentState: boolean) => {
    setTabIndex(currentState ? undefined : -1)
  }, [])

  return (
    <Accordion
      data-testid="frequentlyAskedQuestion"
      id={question.title.replace(/ /g, '_')}
      {...props}
      defaultOpen={expanded}
      onChange={onChangeHandler}
    >
      <AccordionSummary>{question.title}</AccordionSummary>
      <AccordionDetails>
        <Lockup>
          {question.expandContent(tabIndex).map((paragraph, id) => (
            <Text key={id} variant="Body1Regular">
              {paragraph}
            </Text>
          ))}
        </Lockup>
      </AccordionDetails>
    </Accordion>
  )
}

export default FrequentlyAskedQuestion
