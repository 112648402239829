import { Flex } from '@chakra-ui/core'
import FrequentlyAskedQuestion from 'design/components/FrequentlyAskedQuestion/FrequentlyAskedQuestion'
import { LinkExternal } from 'design/components/Typography/Typography'
import { Box, Text, rem } from '@butcherbox/freezer'
import React from 'react'
import { IModuleFAQSection } from './ModuleFAQSection.types'

function ModuleFAQSection({
  id,
  title,
  questions,
  showFAQLink = false,
  containerProps = {},
}: IModuleFAQSection) {
  return (
    <Flex
      backgroundColor="white"
      id={id}
      justifyContent="center"
      py={{ base: rem(50), tablet: rem(100) }}
      {...containerProps}
    >
      <Flex className="faq-section__column" flexDirection="column">
        <Box marginBottom={20}>
          <Text component="h2" textAlign="center" variant="Title">
            {title}
          </Text>
        </Box>
        <Flex flexDirection="column" maxWidth={rem(768)}>
          {questions.map((question, idx) => (
            <FrequentlyAskedQuestion key={idx} question={question} />
          ))}
        </Flex>
        {showFAQLink && (
          <Box paddingTop={24}>
            <Text component="p" textAlign="center" variant="Body1Regular">
              Have additional questions? Check out our{' '}
              <LinkExternal href="https://support.butcherbox.com/hc/en-us">
                FAQ’s
              </LinkExternal>
            </Text>
          </Box>
        )}
      </Flex>
    </Flex>
  )
}

export default ModuleFAQSection
