import { IFrequentlyAskedQuestion } from 'design/components/FrequentlyAskedQuestion/FrequentlyAskedQuestion.types'
import { Body } from 'design/components/Typography/Body'
import { LinkExternal, LinkInternal } from 'design/components/Typography/Links'
import React from 'react'
import {
  List,
  ListItem as StyledListItem,
  ListItemContent,
  UnderlinedText,
} from './FAQs.styles'
import { FAQContent } from './FAQs.types'

const ListItem = ({ children }) => (
  <StyledListItem>
    <ListItemContent>{children}</ListItemContent>
  </StyledListItem>
)

const plansAndPricingPoints: IFrequentlyAskedQuestion[] = [
  {
    title: 'What types of plans do you offer?',
    expandContent: (tabIndex) => [
      `Whether it's for the whole family or just you, we offer a variety of plans so your membership matches your lifestyle. `,
      `We offer 2 types of subscription plans: Custom and Curated.`,
      `
        Custom Plan: Our most popular option allows you to choose specific cuts from any type of
        protein you'd like, including seafood. It's available in 2 sizes: Classic, which includes
        9-14 lbs of meat, and Big, which includes 18-26 lbs of meat.
      `,
      `Here's an example of what a Custom Classic Box might include:`,
      <List key="customClassicBoxList">
        <ListItem>2 x 10oz ribeye steaks</ListItem>
        <ListItem>1 x 2.25 lbs beef shoulder roast</ListItem>
        <ListItem>1 x 3.5 lbs whole chicken</ListItem>
        <ListItem>3 x 1 lb packs of boneless skinless chicken breast</ListItem>
        <ListItem>4 x 8 oz boneless pork chops</ListItem>
        <ListItem>1 x 2 lbs pack wild Alaskan sockeye salmon</ListItem>
      </List>,
      `
        Curated Plan: You choose your proteins (beef, pork, and/or chicken), and we'll pick your
        specific cuts for you. Curated Classic Box Plans are available in 2 sizes, Classic, which
        includes 8-11 lbs of meat, and Big, which includes 16-22 lbs of meat.
      `,
      `
        For example, this is what your Curated order could include if you choose a Mixed Box
        (beef, chicken, and pork) in our Classic Size:
      `,
      <List key="curatedPlanBoxList">
        <ListItem>4 x 8 oz pork chops</ListItem>
        <ListItem>3 x 1 lb packs of boneless skinless chicken breast</ListItem>
        <ListItem>2 x 1 lb packs of ground beef (85/15)</ListItem>
        <ListItem>2 x 10 oz ribeye steaks</ListItem>
        <ListItem>4 x 6 oz flat iron steaks</ListItem>
      </List>,

      `Recurring add-ons and one-time member deals are also always available for purchase to further customize your plan!`,
      <>
        Learn more about{' '}
        <LinkInternal href="/how-it-works/" {...{ tabIndex }}>
          how it works
        </LinkInternal>
        .
      </>,
    ],
  },
  {
    title: 'How much does it cost?',
    expandContent: () => [
      `Good question! Here's a breakdown of our plans and how much they cost:`,
      <Body key="curatedPlanTitle" textDecoration={'underline'}>
        Curated Plan
      </Body>,
      `Classic Box Size: $146`,
      `Big Box Size: $269`,
      <Body key="customPlanTitle">
        <UnderlinedText>Custom Plan</UnderlinedText> (20% more meat than Curated
        plans!)
      </Body>,
      `Classic Box Size: $169`,
      `Big Box Size: $306`,
    ],
  },
  {
    title: 'What types/cuts of meat will I get?',
    expandContent: (tabIndex) => [
      `The types of meat and cuts of meat in your order depend on the plan you select.`,
      `
        With our curated plans, you can choose your proteins (beef, pork, and/or chicken). Then,
        we'll pick your specific cuts, such as flat iron steak, pork tenderloin, or bone-in chicken
        thighs. Our members love that every box is a chance to try something new while enjoying a
        few tried-and-true favorites.
      `,
      `
        With our Custom Plan, you have complete control. You can build your box and choose from 25+
        cut selections each time you order, including seafood. Our selections change regularly, but
        some examples include ribeye steaks, chicken wings, pork breakfast sausage, and wild-caught
        salmon.
      `,
      <>
        Learn more about{' '}
        <LinkInternal href="/how-it-works/" {...{ tabIndex }}>
          how it works
        </LinkInternal>
        .
      </>,
    ],
  },
  {
    title: 'How do I cancel my subscription?',
    expandContent: () => [
      `
        If you decide ButcherBox isn't right for you, we understand. You can easily cancel your
        subscription at any time, and there are never any fees or penalties. You're always welcome
        to resubscribe if you change your mind! If you cancel your subscription after an order has
        already been processed, you will still receive and be charged for that order.
      `,
    ],
  },
  {
    title: 'Can I add extra products to my order?',
    expandContent: () => [
      `Yes, add-ons and member deals are always available to purchase for all plans.`,
      `
        Add-ons are additional cuts of meat that you can include in your recurring order. Lots of
        members choose add-on staples they always want to have on hand, like bacon or ground beef.
        Add-ons are recurring, so they'll be included in your next box and every box after that
        until you make changes.
      `,
      `
        Member deals are one-off items with special savings that you can add to your order,
        like a Buy 2 Get 1 Free for our delicious bacon. Unlike add-ons, they don't renew
        automatically with every box. Member deals are available in limited quantities and
        change often based on season and inventory. They often include cuts that aren't
        available in Custom Plan or curated plan selections, so keep a look out!
      `,
    ],
  },
]

const animalWelfareAndProductInfoPoints: IFrequentlyAskedQuestion[] = [
  {
    title: 'What is 100% grass-fed beef?',
    expandContent: (tabIndex) => [
      <>
        ButcherBox beef is "100% grass-fed," also known as "grass-fed,
        grass-finished." This means our cattle are pasture-raised for their
        entire lives. 100% grass-fed beef is typically leaner and contains a{' '}
        <LinkExternal
          href="https://practicalfarmers.org/research/carcass-characteristics-meat-quality-and-fatty-acid-composition-of-100-grass-fed-beef/#:~:text=On%20average%2C%20the%20omega%2D6,around%2020%3A1%20or%20higher%20%5D."
          {...{ tabIndex }}
        >
          better ratio of omega-3 to omega-6 fatty acids
        </LinkExternal>{' '}
        compared to grain-fed beef.
      </>,
      `
        If you're buying grass-fed beef at the grocery store, you might not be getting what you expect.
        The term "grass-fed" can be misleading because many cows feed on grass freely when they're young,
        but are "finished" as grain-fed and confined to feedlots.
      `,
      <>
        Learn more about our beef{' '}
        <LinkInternal href="/sourcing/" {...{ tabIndex }}>
          here
        </LinkInternal>
        .
      </>,
    ],
  },
  {
    title: 'What do your cows, pigs, and chickens eat?',
    expandContent: (tabIndex) => [
      `
        Our cattle (cows) are 100% grass-fed and roam free on grassy pastures throughout their entire
        lives. They eat their natural diet of grass and may receive other grasses or minerals in
        the winter months. Examples of grasses they eat may include (but are not limited to) annuals,
        perennials, bluegrass, rye grass, and sorghum.
      `,
      `
        Our hogs (pigs) are fed an all-vegetarian diet (with the exception of milk proteins) and
        are raised with traditional, sustainable farming methods.
      `,
      `
        Our chickens eat organic feed (grown with no pesticides and non-GMO seeds) and receive no
        antibiotics or added growth promotants.
      `,
      <>
        Learn more about our animals and sourcing{' '}
        <LinkInternal href="/sourcing/" {...{ tabIndex }}>
          here
        </LinkInternal>
        .
      </>,
    ],
  },
  {
    title: 'Are your animals humanely raised and processed?',
    expandContent: (tabIndex) => [
      `
        Yes, our animals are humanely raised and cared for every step of the way. That's because we
        partner with people who are dedicated to doing the right thing—never cutting corners, and
        always looking for ways to improve.
      `,
      `
        Our farmers' and processors' operating methods are inspired by Dr. Temple Grandin, a member
        of the Humane Farm Animal Care Program's scientific committee. Their facilities are routinely
        inspected and approved by the USDA or a comparable agency.
      `,
      <>
        Learn more about our animals and sourcing{' '}
        <LinkInternal href="/sourcing/" {...{ tabIndex }}>
          here
        </LinkInternal>
        .
      </>,
    ],
  },
  {
    title: 'Is your seafood sustainably sourced?',
    expandContent: (tabIndex) => [
      <>
        Yes, all of our seafood is sustainably harvested and wild-caught. You
        can learn more about the specifics for the different types of seafood we
        offer{' '}
        <LinkInternal href="/sourcing/" {...{ tabIndex }}>
          here
        </LinkInternal>
        .
      </>,
    ],
  },
  {
    title: 'Where does your beef, pork, chicken, and seafood come from?',
    expandContent: () => [
      `
        We work with partners both big and small that are committed to doing the right thing from
        start to finish. We are very intentional about the companies that we allow into our program,
        which means we carefully vet all of our suppliers to make sure that they meet our
        specifications and claims. Regardless of their size or location, all operations and f
        acilities are routinely inspected to maintain the highest quality for our members. To hold
        ourselves and our suppliers accountable, we also rely on third-party audits from organizations
        like Certified Humane and Global Animal Partnership (GAP).
      `,
      `
        When we started ButcherBox in 2015, we sourced all of our beef from Australia where they've
        built an entire system on certifying pasture-raised meat, something that does not current
        exist in the U.S. Australia has very high animal welfare standards as well as the climate
        and pastureland to allow cattle to spend their entire lives grazing.
      `,
      `
        We have also invested in developing the grass-fed beef industry in the U.S. for both
        sustainability and scalability. As of today, 20 percent of all our grass-fed, grass-finished
        beef comes from our own domestic program.
      `,
      `
        Our pork and chicken come from the US and Canada, and other proteins such as wild-caught
        Alaskan sockeye salmon, wild-caught cod, bison, and turkey all come from the U.S.
      `,
    ],
  },
  {
    title:
      'Where can I find the ingredients and nutrition facts for your products?',
    expandContent: (tabIndex) => [
      <>
        While the majority of our products are raw and include one simple
        ingredient—beef, pork, chicken, etc.—we also offer fully cooked products
        and other items that have additional ingredients. You can see the labels
        and nutritional information{' '}
        <LinkInternal href="/specialty-food-ingredients/" {...{ tabIndex }}>
          here
        </LinkInternal>
        .
      </>,
    ],
  },
]

const shippingAndDeliveryPoints: IFrequentlyAskedQuestion[] = [
  {
    title: `When do I find out what's coming in my order?`,
    expandContent: () => [
      `
        With our Custom Plan, you can choose exactly which cuts of meat you'll be getting in your
        order. You can edit your selections any time before the date your order bills.
      `,
      <>
        With our curated plans (Beef &amp; Chicken, Beef &amp; Pork, Mixed, or
        All Beef), you can choose whether you'd like beef, pork, and/or chicken,
        and we'll pick your specific cuts. You will find out what's in your
        order when you open it on delivery day—our members love the surprise!
      </>,
    ],
  },
  {
    title: `When will my order arrive after I've placed it?`,
    expandContent: () => [
      `
        Your box will be delivered within approximately 2-5 business days from the date your order
        bills. Here's a quick guide to our order and delivery timeline:
      `,
      <List key="estimatedDeliveryList">
        <ListItem>
          Your order will be packed with the optimal amount of dry ice so that
          your meat arrives cold.
        </ListItem>
        <ListItem>
          Your order will ship 1-2 days from the date you place it (or 1-2 days
          after the date your order bills for recurring boxes).
        </ListItem>
        <ListItem>
          As soon as your order ships, you'll receive an email with tracking
          information. You can also sign up for SMS notifications at
          butcherbox.com/sms.
        </ListItem>
        <ListItem>
          Most orders are delivered 1-2 days after they ship. Our carriers
          typically deliver between 8-8pm, with a small number of deliveries
          occurring outside of those hours. Check your tracking information to
          follow your order!
        </ListItem>
      </List>,
    ],
  },
  {
    title:
      'Do I have to put everything into the freezer immediately after my order is delivered?',
    expandContent: () => [
      `
        You don't have to be home to sign for or accept your delivery, but all orders should be
        refrigerated or frozen as soon as possible.
      `,
      <>
        When delivered on time and retrieved within 6-8 hours of delivery, your
        ButcherBox products should arrive refrigerator cold—below 40&deg;F as
        measured with a food thermometer. Your meats could arrive partly frozen
        (solid) or frozen and can be placed in the freezer for later use.
      </>,
    ],
  },
  {
    title: 'My schedule changes often, how flexible are deliveries?',
    expandContent: () => [
      `
        Extremely! In a few clicks, you can change the date your next order will be billed. Your box
        will be delivered 2-5 days from the date your order bills.
      `,
      `You can also update your delivery frequency (every 2 weeks, 4 weeks, 6 weeks, etc.) to match your schedule.`,
      `
        Please note that changes to your delivery frequency go into effect after your next scheduled
        order. If you want the change to be effective immediately, please make sure to change both
        your box frequency and your upcoming bill date.
      `,
    ],
  },
  {
    title: 'How often will I receive deliveries?',
    expandContent: () => [
      `
        It's totally up to you! Most of our members choose to get an order every 4 weeks, but you
        can set your delivery frequency (every 2 weeks, 4 weeks, 6 weeks, etc.) in just a few clicks.
        You can also change the date your next order will be billed. Your box will be delivered
        2-5 days from the date your order bills.
      `,
      `
        Please note that changes to your delivery frequency go into effect after your next scheduled
        order. If you want the change to be effective immediately, please make sure to change both
        your box frequency and your upcoming bill date.
      `,
    ],
  },
]

const faqData: FAQContent = {
  plansAndPricing: {
    id: 'PlansAndPricing',
    title: 'Plans and Pricing',
    questions: plansAndPricingPoints,
  },
  animalWelfareAndProductInfo: {
    id: 'AnimalWelfareAndNutritionInfo',
    title: 'Animal Welfare and Nutrition Information',
    questions: animalWelfareAndProductInfoPoints,
  },
  shippingAndDelivery: {
    id: 'ShippingAndDelivery',
    title: 'Shipping and Delivery',
    questions: shippingAndDeliveryPoints,
  },
}

export default faqData
