import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'
import { TwoColorIconDefinition } from '../types'
import theme from 'design/theme'

export const def: TwoColorIconDefinition = (
  colors = { base: 'currentColor', accent: 'currentColor' }
) => {
  return {
    id: 'chatBubble',
    name: 'Chat Bubble',
    category: 'misc',
    path: (
      <>
        <g>
          <path
            d="m12.2791 2.58504c-1.5256-1.20964-3.51632-1.694352-5.45121-1.37708-3.42325.59535-5.934883 3.77542-5.69302 7.23721.12309 1.80523.95183 3.48943 2.30697 4.68833.20466.1662.24186.4652.11163.707-.33488.5582-.59568.9303-.98604 1.3213.8186-.0558 1.80465-.2605 2.47441-.6884.0746-.0454.15826-.0738.24507-.0833.0868-.0095.17463.0003.25726.0285 1.17283.451 2.44653.5732 3.68372.3535 3.40461-.615 5.89771-3.7964 5.65581-7.23822-.0662-.96065-.3321-1.89693-.7805-2.74905-.4485-.85211-1.0698-1.60133-1.8241-2.19979z"
            fill={colors.accent}
          />
          <path
            d="m12.9488 1.71031c-.883-.6891-1.8997-1.18716-2.98537-1.462593-1.08571-.2754325-2.21682-.3222241-3.32158-.137407-3.98139.687044-6.902655 4.3721-6.6232531 8.42791.1299001 1.95348.9485051 3.75818 2.3438531 5.11628-.26046.3907-.50199.6512-.89302 1.0047-.14175.1239-.24254.288-.28904.4704-.04651.1825-.03655.3748.02857.5515.06.1793.17499.3351.32863.4454.15364.1102.3381.1692.52719.1685h.13056c.89302 0 2.19502-.186 3.2-.7445 1.29052.4492 2.67505.5582 4.01993.3163 3.96143-.6867 6.84483-4.389 6.56483-8.40765-.0771-1.11649-.3865-2.20458-.9085-3.19454-.522-.98997-1.245-1.85998-2.1228-2.5543zm-3.7209 13.06079c-1.2371.2201-2.5108.0982-3.68372-.3525-.0827-.0281-.17057-.0376-.25738-.028-.0868.0097-.17043.0383-.24495.0838-.66976.4279-1.65581.6313-2.47442.6884.38937-.392.65117-.7641.98738-1.3222.13023-.2419.09302-.5396-.11163-.707-1.35564-1.1988-2.18486-2.883-2.3083-4.6884-.241865-3.4618 2.26976-6.64187 5.69302-7.23721 1.93488-.316284 3.9256.16611 5.4512 1.37707.7545.59862 1.3758 1.34804 1.8243 2.2004.4485.85235.7143 1.78889.7803 2.74977.2419 3.44057-2.2511 6.62197-5.6558 7.23587z"
            fill={colors.base}
          />
        </g>
      </>
    ),
    viewBox: '0 0 16 17',
  }
}

export const IconChatBubble = makeIcon(
  def({ base: theme.colors.bb.spicedCrimson, accent: 'white' })
)
export default IconChatBubble
